html, body {
  height: 100%;
  max-width: 100%;
}

body {
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.headernav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a.headernav:hover {
  color: #696773;
  cursor: pointer;
}

a.content-link {
  color: #006170;
  font-family: "Heebo", sans-serif;
  font-weight: 900;
}

a.content-link:hover {
  color: #2A324B;
  text-decoration: underline;
  text-decoration-color: #2A324B;
  font-family: "Heebo", sans-serif;
  font-weight: 900;
}

a.action-area-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  min-width: 60px;
  border-radius: 30px;
  background-color: #2A324B;
  padding: 12px;
  color: #FFFFFF;
}

a.action-area-link:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  min-width: 60px;
  border-radius: 30px;
  background-color: #FED766;
  padding: 12px;
  color: #2A324B;
}

a.flat-button {
  font-family: "Heebo";
  font-weight: 900;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #2A324B;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  flex-grow: 0;
  flex-shrink: 1;
}

a.flat-button:hover {
  font-family: "Heebo";
  font-weight: 900;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #696773;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  flex-grow: 0;
  flex-shrink: 1;
}

a.primary-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #FCA311;
  border-radius: 24px;
  font-family: "Heebo", sans-serif;
  color: #2A324B;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;
  transition: background-color .5s;
}

a.primary-button:hover {
  background-color: #43BCCD;
  transition: background-color .5s;
}

.feature-bar a.primary-button {
  margin-top: 12px;
  margin-bottom: 72px;
}

a.secondary-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: transparent;
  border-radius: 24px;
  border: 2px solid #43BCCD;
  font-family: "Heebo", sans-serif;
  color: #2A324B;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;
  transition: border .5s;
}

a.secondary-button:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: transparent;
  border-radius: 24px;
  border: 2px solid #AF125A;
  font-family: "Heebo", sans-serif;
  color: #2A324B;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;
  transition: border .5s;
}

.portfolio-inner-row a.secondary-button {
  align-self: center;
}

.max-width-container {
  width: 100%;
  max-width: 880px;
}

.max-width-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.store-buttons-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 880px;
}

.store-button-left, .store-button-right {
  width: 200px;
  margin-bottom: 24px;
}

.full-width-container {
  width: 100%;
  background-color: #EFF1F3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.progress-meter-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 12px;
}

.headline-1 {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 122.5%;
  letter-spacing: -0.02em;
  color: #202020;
  width: 100%;
  text-align: left;
}

.headline-2 {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  color: #202020;
  width: 100%;
  text-align: center;
}

.headline-3 {
  font-family: "Heebo";
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #202020;
}

.tutorial-title {
  font-family: "Heebo";
  font-weight: 300;
  font-size: 36px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.tutorial-subtitle {
  font-family: "Heebo";
  font-weight: 900;
  font-size: 36px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.subheadline {
  font-family: "Heebo";
  font-weight: 300;
  font-size: 14px;
  line-height: 17.15px;
  letter-spacing: -0.02em;
  color: #202020;
  text-align: center;
}

.content-text {
  font-family: "Heebo", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #202020;
}

.content-text-bold {
  font-weight: 900;
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 99;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.25);
}

.header-container {
  background-color: #2A324B;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  justify-content: flex-start;
  height: 77px;
  flex-grow: 1;
  padding-left: 12px;
}

.menu-button {
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}

.menu-container-visible {
  display: flex;
  flex-direction: column;
  border: 12px solid #FFFFFF;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  touch-action: none;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  background-color: #2A324B;
}

.menu-container-hidden {
  display: none;
  flex-direction: column;
  margin: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
}

.menu-item-container-visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-item-container-hidden {
  display: none;
}

.menu-close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.menu-item {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-item:after {
  content: "";
  display: flex;
  width: 0%;
  border-bottom: 4px solid #43BCCD;
  transition: .5s;
  align-self: center;
}

.menu-item:hover:after {
  width: 100%;
}

.menu-item-active {
  margin-bottom: 24px;
  border-bottom: 4px solid #FCA311;
}

.menu-item-active-text {
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.menu-item-text {
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #D4D6DB;
}

.contact-button-mobile {
  width: 152px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #AF125A;
  color: #FFFFFF;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  margin-top: 24px;
}

.contact-button-header {
  height: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #AF125A;
  color: #FFFFFF;
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding-left: 12px;
  padding-right: 12px;
  transition: background-color .5s, color .5s;
}

a.contact-button-header:hover {
  background-color: #FCA311;
  color: #202020;
  transition: background-color .5s, color .5s;
}

.menu-logo-letters {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 36px;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.logo-letters {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  color: #FFFFFF;
  font-size: 36px;
}

.hero-row {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 77px;
}

.hero-row-no-reverse {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 77px;
}

.home-hero-image {
  width: 100%;
  object-fit: cover;
}

.about-hero-image {
  width: 100%;
  object-fit: cover;
}

.feature-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #202020;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 36px;
  padding-right: 36px;
}

.portfolio-feature-bar {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  background-color: #202020;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 36px;
  padding-right: 36px;
}

.portfolio-feature-bar .feature-bar-single-column a.primary-button {
  align-self: center;
}

.portfolio-feature-bar .feature-bar-tag {
  margin-top: 24px;
}

.portfolio-feature-bar .headline-2, .portfolio-feature-bar .subheadline {
  text-align: left;
}

.feature-bar-short-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
}

.feature-bar-single-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  max-width: 880px;
}

.feature-bar-tag {
  font-family: "Heebo", sans-serif;
  color: #FCA311;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.recent-work-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  max-width: 250px;
}

.recent-work-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  filter: drop-shadow(6px 9px 12px rgba(0, 0, 0, 0.4));
  margin-top: 12px;
  margin-bottom: 12px;
}

.recent-work-image {
  width: 100%;
  object-fit: contain;
}

.recent-work-text-container {
  background-color: #373737;
  padding: 12px;
  font-family: "Heebo", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  width: 100%;
}

.recent-work-text-highlight {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #FCA311;
}

.blank-row {
  height: 48px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.portfolio-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 100%;
}

.portfolio-inner-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.portfolio-img {
  width: 100%;
  padding-right: 36px;
  padding-left: 36px;
}

.portfolio-hero-img {
  width: 100%;
}

.call-to-action-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #AF125A;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 36px;
  padding-bottom: 36px;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2A324B;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.call-to-action-bar a.primary-button {
  width: 160px;
  margin-top: 24px;
  margin-bottom: 36px;
}

.callout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.callout-reverse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.callout-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yellow-circle {
  background-color: #FED766;
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.yellow-circle-icon {
  color: #2A324B;
  font-size: 96px;
}

.action-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 72px;
  margin-bottom: 72px;
}

.action-column-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 48px;
}

.action-column-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 48px;
}

.picture-caption {
  background-color: #EFF1F3;
  padding: 12px;
  font-family: "Heebo", sans-serif;
  font-weight: 300;
  color: #202020;
  font-size: 14px;
  margin-top: -8px;
}

.picture-border {
  border: 1px solid #EFF1F3;
}

.phone-image {
  width: 175px;
  height: auto;
}

.feature-phone-image {
  width: 175px;
  height: auto;
}

.catfight-logo {
  width: 150px;
  height: auto;
}

.home-hero {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 36px;
}

.home-hero-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 24px;
}

.home-hero-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 12px;
}

.tutorial-hero-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tutorial-hero-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.code-snippet {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 60px;
  padding: 24px;
  background-color: #E5E5E5;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.inline-code {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: #2A324B;
}

  /* ASK OUIJA STYLES */
.ao-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    background-color: #221E22;
}

.ao-game-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.ao-greetings-image {
  flex-grow: 1;
}

.ao-game-bar {
  height: 320px;
  background-color: #221E22;
  flex-grow: 0;
  padding: 12px;
  width: 100%;
}

.ao-headline {
  font-family: "Heebo", sans-serif;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
}

.ao-text {
  font-family: "Heebo", sans-serif;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.ao-primary-button {
  width: 100%;
  padding: 12px;
  background-color: #ECA72C;
  border-radius: 2px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ao-primary-button:hover {
  background-color: #E9B14F;
}

.ao-primary-button:active {
  background-color: #FFD893
}

.ao-primary-button-disabled {
  width: 100%;
  padding: 12px;
  background-color: #464646;
  border-radius: 2px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ao-primary-button-text {
  font-family: "Heebo", sans-serif;
  color: #221E22;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.ao-secondary-button {
  width: 100%;
  padding: 12px;
  border-radius: 2px;
  margin-top: 12px;
  border: 1px solid #ECA72C;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ao-secondary-button:hover {
  border: 1px solid #E9B14F;
}

.ao-secondary-button:active {
  border: 1px solid #FFD893;
}

.ao-secondary-button-disabled {
  width: 100%;
  padding: 12px;
  border: 1px solid #464646;
  border-radius: 2px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ao-secondary-button-text {
  font-family: "Heebo", sans-serif;
  color: #ECA72C;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.ao-secondary-button-disabled-text {
  font-family: "Heebo", sans-serif;
  color: #464646;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.ao-game-title {
  font-family: "Megrim", cursive;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  margin-bottom: 12px;
}

.ao-or-divider-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}

.ao-or-divider {
  height: 1px;
  background-color: #A7A5A7;
  flex-grow: 1;
}

.ao-or-divider-text {
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

.ao-enter-code-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ao-code-textbox {
  background-color: #383438 !important;
  padding: 12px;
  text-align: center;
  color: #FFFFFF;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  font-size: 24px;
  flex-grow: 1;
  border-bottom: none !important;
  text-transform: uppercase;
  margin-bottom: 0px !important
}

.ao-textbox {
  background-color: #383438 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  text-align: left;
  color: #FFFFFF;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 14px !important;
  flex-grow: 1;
  border-bottom: none !important;
  margin-bottom: 0px !important;
  box-sizing: border-box !important;
}

.ao-lobby-container {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 12px;
}

.ao-lobby-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 1;
}

.ao-game-code {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  color: #FFFFFF;
  font-size: 24px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 12px;
}

.ao-player-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;
}

.ao-player-row-avatar {
  margin-right: 12px;
  flex-grow: 0;
  width: 36px;
  height: 36px;
}

.ao-player-row-name {
  flex-grow: 1;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
}

.ao-question-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  background-color: #221E22;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  margin-bottom: 12px;
}

.ao-question-text {
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  text-align: left;
}

.ao-answer-space {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
}

.ao-answer-letter-tile {
  background-color: #221E22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  height: 40px;
  width: 26px;
  flex-shrink: 0;
}

.ao-answer-letter-tile-selected {
  background-color: #ECA72C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  height: 40px;
  width: 26px;
  flex-shrink: 0;
}

.ao-answer-letter-tile-text {
  font-family: "Megrim", cursive;
  font-size: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 900;
}

.ao-answer-letter-tile-selected-text {
  font-family: "Megrim", cursive;
  font-size: 24px;
  color: #221E22;
  text-transform: uppercase;
  font-weight: 900;
}

.ao-spirit-watching-game-bar {
  width: 100%;
  height: 206px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.ao-spirit-watching-game-bar-text {
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}

.ao-letter-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #383438;
  padding: 12px;
  border-radius: 4;
  overflow-x: scroll;
  max-width: 100%;
}

.cssload-dots {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	outline: 1px solid red;
	filter: url(#goo);
		-o-filter: url(#goo);
		-ms-filter: url(#goo);
		-webkit-filter: url(#goo);
		-moz-filter: url(#goo);
}

.cssload-dot {
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
}
.cssload-dot:before {
	content: "";
	width: 34px;
	height: 34px;
	border-radius: 49px;
	background: rgb(252,163,17);
	position: absolute;
	left: 50%;
	transform: translateY(0);
		-o-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
	margin-left: -17.5px;
	margin-top: -17.5px;
}



.cssload-dot:nth-child(5):before {
	z-index: 100;
	width: 44.5px;
	height: 44.5px;
	margin-left: -21.75px;
	margin-top: -21.75px;
	animation: cssload-dot-colors 3.6s ease infinite;
		-o-animation: cssload-dot-colors 3.6s ease infinite;
		-ms-animation: cssload-dot-colors 3.6s ease infinite;
		-webkit-animation: cssload-dot-colors 3.6s ease infinite;
		-moz-animation: cssload-dot-colors 3.6s ease infinite;
}


.cssload-dot:nth-child(1) {
	animation: cssload-dot-rotate-1 3.6s 0s linear infinite;
		-o-animation: cssload-dot-rotate-1 3.6s 0s linear infinite;
		-ms-animation: cssload-dot-rotate-1 3.6s 0s linear infinite;
		-webkit-animation: cssload-dot-rotate-1 3.6s 0s linear infinite;
		-moz-animation: cssload-dot-rotate-1 3.6s 0s linear infinite;
}
.cssload-dot:nth-child(1):before {
	background-color: rgb(175,18,90);
	animation: cssload-dot-move 3.6s 0s ease infinite;
		-o-animation: cssload-dot-move 3.6s 0s ease infinite;
		-ms-animation: cssload-dot-move 3.6s 0s ease infinite;
		-webkit-animation: cssload-dot-move 3.6s 0s ease infinite;
		-moz-animation: cssload-dot-move 3.6s 0s ease infinite;
}

.cssload-dot:nth-child(2) {
	animation: cssload-dot-rotate-2 3.6s 0.9s linear infinite;
		-o-animation: cssload-dot-rotate-2 3.6s 0.9s linear infinite;
		-ms-animation: cssload-dot-rotate-2 3.6s 0.9s linear infinite;
		-webkit-animation: cssload-dot-rotate-2 3.6s 0.9s linear infinite;
		-moz-animation: cssload-dot-rotate-2 3.6s 0.9s linear infinite;
}
.cssload-dot:nth-child(2):before {
	background-color: rgb(67,188,205);
	animation: cssload-dot-move 3.6s 0.9s ease infinite;
		-o-animation: cssload-dot-move 3.6s 0.9s ease infinite;
		-ms-animation: cssload-dot-move 3.6s 0.9s ease infinite;
		-webkit-animation: cssload-dot-move 3.6s 0.9s ease infinite;
		-moz-animation: cssload-dot-move 3.6s 0.9s ease infinite;
}

.cssload-dot:nth-child(3) {
	animation: cssload-dot-rotate-3 3.6s 1.8s linear infinite;
		-o-animation: cssload-dot-rotate-3 3.6s 1.8s linear infinite;
		-ms-animation: cssload-dot-rotate-3 3.6s 1.8s linear infinite;
		-webkit-animation: cssload-dot-rotate-3 3.6s 1.8s linear infinite;
		-moz-animation: cssload-dot-rotate-3 3.6s 1.8s linear infinite;
}
.cssload-dot:nth-child(3):before {
	background-color: rgb(62,195,0);
	animation: cssload-dot-move 3.6s 1.8s ease infinite;
		-o-animation: cssload-dot-move 3.6s 1.8s ease infinite;
		-ms-animation: cssload-dot-move 3.6s 1.8s ease infinite;
		-webkit-animation: cssload-dot-move 3.6s 1.8s ease infinite;
		-moz-animation: cssload-dot-move 3.6s 1.8s ease infinite;
}

.cssload-dot:nth-child(4) {
	animation: cssload-dot-rotate-4 3.6s 2.7s linear infinite;
		-o-animation: cssload-dot-rotate-4 3.6s 2.7s linear infinite;
		-ms-animation: cssload-dot-rotate-4 3.6s 2.7s linear infinite;
		-webkit-animation: cssload-dot-rotate-4 3.6s 2.7s linear infinite;
		-moz-animation: cssload-dot-rotate-4 3.6s 2.7s linear infinite;
}
.cssload-dot:nth-child(4):before {
	background-color: rgb(252,163,17);
	animation: cssload-dot-move 3.6s 2.7s ease infinite;
		-o-animation: cssload-dot-move 3.6s 2.7s ease infinite;
		-ms-animation: cssload-dot-move 3.6s 2.7s ease infinite;
		-webkit-animation: cssload-dot-move 3.6s 2.7s ease infinite;
		-moz-animation: cssload-dot-move 3.6s 2.7s ease infinite;
}

@keyframes cssload-dot-move {
	0% {
		transform: translateY(0);
	}
	18%, 22% {
		transform: translateY(-68px);
	}
	40%, 100% {
		transform: translateY(0);
	}
}

@-o-keyframes cssload-dot-move {
	0% {
		-o-transform: translateY(0);
	}
	18%, 22% {
		-o-transform: translateY(-68px);
	}
	40%, 100% {
		-o-transform: translateY(0);
	}
}

@-ms-keyframes cssload-dot-move {
	0% {
		-ms-transform: translateY(0);
	}
	18%, 22% {
		-ms-transform: translateY(-68px);
	}
	40%, 100% {
		-ms-transform: translateY(0);
	}
}

@-webkit-keyframes cssload-dot-move {
	0% {
		-webkit-transform: translateY(0);
	}
	18%, 22% {
		-webkit-transform: translateY(-68px);
	}
	40%, 100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes cssload-dot-move {
	0% {
		-moz-transform: translateY(0);
	}
	18%, 22% {
		-moz-transform: translateY(-68px);
	}
	40%, 100% {
		-moz-transform: translateY(0);
	}
}

@keyframes cssload-dot-colors {
	0% {
		background-color: rgb(252,163,17);
	}
	25% {
		background-color: rgb(175,18,90);
	}
	50% {
		background-color: rgb(67,188,205);
	}
	75% {
		background-color: rgb(62,195,0);
	}
	100% {
		background-color: rgb(252,163,17);
	}
}

@-o-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(252,163,17);
	}
	25% {
		background-color: rgb(175,18,90);
	}
	50% {
		background-color: rgb(67,188,205);
	}
	75% {
		background-color: rgb(62,195,0);
	}
	100% {
		background-color: rgb(252,163,17);
	}
}

@-ms-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(252,163,17);
	}
	25% {
		background-color: rgb(175,18,90);
	}
	50% {
		background-color: rgb(67,188,205);
	}
	75% {
		background-color: rgb(62,195,0);
	}
	100% {
		background-color: rgb(252,163,17);
	}
}

@-webkit-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(252,163,17);
	}
	25% {
		background-color: rgb(175,18,90);
	}
	50% {
		background-color: rgb(67,188,205);
	}
	75% {
		background-color: rgb(62,195,0);
	}
	100% {
		background-color: rgb(252,163,17);
	}
}

@-moz-keyframes cssload-dot-colors {
	0% {
		background-color: rgb(252,163,17);
	}
	25% {
		background-color: rgb(175,18,90);
	}
	50% {
		background-color: rgb(67,188,205);
	}
	75% {
		background-color: rgb(62,195,0);
	}
	100% {
		background-color: rgb(252,163,17);
	}
}

@keyframes cssload-dot-rotate-1 {
	0% {
		transform: rotate(-105deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

@-o-keyframes cssload-dot-rotate-1 {
	0% {
		-o-transform: rotate(-105deg);
	}
	100% {
		-o-transform: rotate(270deg);
	}
}

@-ms-keyframes cssload-dot-rotate-1 {
	0% {
		-ms-transform: rotate(-105deg);
	}
	100% {
		-ms-transform: rotate(270deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-1 {
	0% {
		-webkit-transform: rotate(-105deg);
	}
	100% {
		-webkit-transform: rotate(270deg);
	}
}

@-moz-keyframes cssload-dot-rotate-1 {
	0% {
		-moz-transform: rotate(-105deg);
	}
	100% {
		-moz-transform: rotate(270deg);
	}
}

@keyframes cssload-dot-rotate-2 {
	0% {
		transform: rotate(165deg);
	}
	100% {
		transform: rotate(540deg);
	}
}

@-o-keyframes cssload-dot-rotate-2 {
	0% {
		-o-transform: rotate(165deg);
	}
	100% {
		-o-transform: rotate(540deg);
	}
}

@-ms-keyframes cssload-dot-rotate-2 {
	0% {
		-ms-transform: rotate(165deg);
	}
	100% {
		-ms-transform: rotate(540deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-2 {
	0% {
		-webkit-transform: rotate(165deg);
	}
	100% {
		-webkit-transform: rotate(540deg);
	}
}

@-moz-keyframes cssload-dot-rotate-2 {
	0% {
		-moz-transform: rotate(165deg);
	}
	100% {
		-moz-transform: rotate(540deg);
	}
}

@keyframes cssload-dot-rotate-3 {
	0% {
		transform: rotate(435deg);
	}
	100% {
		transform: rotate(810deg);
	}
}

@-o-keyframes cssload-dot-rotate-3 {
	0% {
		-o-transform: rotate(435deg);
	}
	100% {
		-o-transform: rotate(810deg);
	}
}

@-ms-keyframes cssload-dot-rotate-3 {
	0% {
		-ms-transform: rotate(435deg);
	}
	100% {
		-ms-transform: rotate(810deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-3 {
	0% {
		-webkit-transform: rotate(435deg);
	}
	100% {
		-webkit-transform: rotate(810deg);
	}
}

@-moz-keyframes cssload-dot-rotate-3 {
	0% {
		-moz-transform: rotate(435deg);
	}
	100% {
		-moz-transform: rotate(810deg);
	}
}

@keyframes cssload-dot-rotate-4 {
	0% {
		transform: rotate(705deg);
	}
	100% {
		transform: rotate(1080deg);
	}
}

@-o-keyframes cssload-dot-rotate-4 {
	0% {
		-o-transform: rotate(705deg);
	}
	100% {
		-o-transform: rotate(1080deg);
	}
}

@-ms-keyframes cssload-dot-rotate-4 {
	0% {
		-ms-transform: rotate(705deg);
	}
	100% {
		-ms-transform: rotate(1080deg);
	}
}

@-webkit-keyframes cssload-dot-rotate-4 {
	0% {
		-webkit-transform: rotate(705deg);
	}
	100% {
		-webkit-transform: rotate(1080deg);
	}
}

@-moz-keyframes cssload-dot-rotate-4 {
	0% {
		-moz-transform: rotate(705deg);
	}
	100% {
		-moz-transform: rotate(1080deg);
	}
}


@media only screen and (min-width: 959px) {
  a.headernav {
    height: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .headline-1 {
    font-family: "Heebo", sans-serif;
    font-weight: 900;
    font-size: 48px;
    line-height: 122.5%;
    letter-spacing: -0.02em;
    color: #202020;
    width: 100%;
    text-align: left;
    max-width: 880px;
  }

  .headline-2 {
    font-family: "Heebo", sans-serif;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #202020;
    width: 100%;
    text-align: left;
    max-width: 880px;
  }

  .headline-3 {
    font-size: 24px;
    line-height: 35px;
  }

  a.primary-button {
    width: auto;
    padding-left: 36px;
    padding-right: 36px;
  }

  a.secondary-button {
    width: auto;
    padding-left: 36px;
    padding-right: 36px;
  }

  a.secondary-button:hover {
    width: auto;
    padding-left: 36px;
    padding-right: 36px;
  }

  .portfolio-inner-row a.secondary-button {
    align-self: flex-start;
  }

  a.flat-button {
    font-size: 14px;
  }

  a.flat-button:hover {
    font-size: 14px;
  }

  .subheadline {
    font-family: "Heebo";
    font-weight: 300;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #202020;
    text-align: left;
    max-width: 880px;
  }

  .content-text {
    font-family: "Heebo", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #202020;
    line-height: 32px;
    letter-spacing: -0.02em;
  }

  .full-width-container {
    padding-right: 12px;
    padding-left: 12px;
  }

  .hero-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 670px;
    margin-top: 107px;
  }

  .hero-row-no-reverse {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 670px;
    margin-top: 107px;
  }

  .logo-letters {
    font-family: "Heebo", sans-serif;
    font-weight: 900;
    font-size: 48px;
    color: #FFFFFF;
    margin-left: 12px;
  }

  .header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    padding-top: 12px;
    left: 12px;
    right: 12px;
    top: 0px;
    position: fixed;
    width: auto;
  }

  .header-container {
    justify-content: center;
    height: 95px;
    justify-content: flex-start;
  }

  .menu-button {
    display: none;
  }

  .menu-close {
    display: none;
  }

  .menu-container-visible {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    position: relative;
    background-color: #2A324B;
    height: 95px;
    margin: 0px;
    border: none;
  }

  .menu-container-hidden {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    position: relative;
    background-color: #2A324B;
    height: 95px;
    margin: 0px;
  }

  .menu-item-container-visible {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .menu-item-container-hidden {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .menu-item {
    margin-right: 36px;
    margin-bottom: 0px;
  }

  .menu-item-active {
    margin-right: 36px;
    margin-bottom: 0px;
  }

  .menu-logo-letters {
    display: none;
  }

  .contact-button-header {
    height: 95px;
    min-width: 177px;
    font-size: 18px;
  }

  .contact-button-mobile {
    display: none;
  }

  .home-hero-image {
    width: 47%;
    object-fit: cover;
    display: flex;
    align-self: flex-start;
  }

  .about-hero-image {
    width: 52%;
    object-fit: cover;
    display: flex;
    align-self: flex-start;
  }

  .feature-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    background-color: #202020;
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .portfolio-feature-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    background-color: #202020;
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .portfolio-feature-bar .feature-bar-single-column a.primary-button {
    align-self: flex-start;
  }

  .feature-bar-tag {
    font-family: "Heebo", sans-serif;
    color: #FCA311;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.07em;
  }

  .feature-bar-short-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 156px;
    max-width: 400px;
  }

  .feature-bar a.primary-button {
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .recent-work-text-container {
    font-size: 18px;
    line-height: 32px;
  }

  .recent-work-text-highlight {
    font-size: 18px;
    font-weight: 900;
    color: #FCA311;
  }

  .blank-row {
    height: 72px;
  }

  .portfolio-inner-row {
    flex-direction: row;
  }

  .portfolio-img {
    width: 33%;
  }

  .portfolio-hero-img {
    width: 60%;
    filter: drop-shadow(6px 9px 12px rgba(0, 0, 0, 0.25));
    margin: 24px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2A324B;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-right: 12px;
    margin-left: 12px;
    border-bottom: 12px solid #FFFFFF;
  }

  .about-image {
    height: 500px;
  }

  .menu-container {
    justify-content: flex-end;
  }

  .callout {
    flex-direction: row;
  }

  .callout-reverse {
    flex-direction: row-reverse;
  }

  .yellow-circle {
    margin-bottom: 0px;
    margin-right: 36px;
  }

  .store-buttons-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 880px;
  }

  .store-button-left {
    height: 50px;
    width: auto;
    margin-right: 18px;
  }

  .store-button-right {
    height: 50px;
    width: auto;
    margin-left: 18px;
  }

  .phone-image {
    width: 392px;
    height: auto;
  }

  .feature-phone-image {
    width: 295px;
    height: auto;
  }

  .catfight-logo {
    width: 266px;
    height: auto;
  }

  .max-width-inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 880px;
  }

  .home-hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .home-hero-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 24px;
    width: 50%
  }

  .tutorial-hero-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .tutorial-hero-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .tutorial-title {
    font-family: "Heebo";
    font-weight: 300;
    font-size: 64px;
    line-height: 122.5%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  .tutorial-subtitle {
    font-family: "Heebo";
    font-weight: 900;
    font-size: 64px;
    line-height: 122.5%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  /* ask ouija styles */
  .ao-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    background-color: #221E22;
  }

  .ao-game-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 568px;
    width: 320px;
  }
}

@media only screen and (min-width: 1024px) {
  .recent-work-column {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    max-width: 50%;
  }

  .recent-work-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 29%;
    max-width: 400px;
    filter: drop-shadow(6px 9px 12px rgba(0, 0, 0, 0.4));
    margin-right: 12px;
    margin-left: 12px;
    flex-grow: 1;
  }

  .recent-work-text-container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
  }
}
